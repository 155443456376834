/* Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
    position: fixed;
    left: 0;
    overflow: scroll;
}

.login-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    width: 100%;
}

.login-form h2 {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group textarea:focus-visible {
    outline: unset;
}

.login-button {
    width: 100%;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #285185;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.login-button:hover {
    background-color: #0056b3;
}
.text {
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    fill: #333;
}

.highlight {
    fill: #d67940;
    font-weight: bold;
    font-size: 35px;
}

.register-link {
    margin-top: 10px;
    text-align: center;
}

.register-link p {
    font-size: 14px;
}

.register-link a {
    color: #285185; /* İstediğin renk ile değiştirebilirsin */
    text-decoration: none;
}

.register-link a:hover {
    text-decoration: underline;
}


.page-container {
    background: #ecf1f4;
    width: 100%;
    height: 100%;
    position: fixed;
}

.login-header {
    height: 100px;
    background: #fff;
    box-shadow: 1px -13px 20px 0 black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
}

.login-body {
    height: calc(100% - 130px);
    display: flex;
}

.login-body-right {
    width: 50%;
    display: grid;
    align-content: start;
    justify-content: left;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 100px;
}

.login-body-right p {
    font-size: 14px;
    margin-top: 20px;
}

.login-body-right a {
    font-size: 14px;
    margin-top: 10px;
    background: #285185;
    width: 150px;
    padding: 10px 30px;
    color: #fff;
    text-decoration: unset;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-body-right a:hover {
    color: #fff;
    text-decoration: unset;
}

.login-body-left {
    width: 50%;
}

.login-footer {
    background: #ccd9e2;
    color: #285185;
    font-size: 12px;
    font-weight: 700;
    height: 30px;
    padding: 0 10px;
}

.login-right-area form {
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 0 10px;
}

.login-right-area form input {
    padding: 2px 10px;
    border: 1px solid #ddd;
    font-size: 12px;
    color: #000;
}

.login-right-area form button {
    background: unset;
    border: 1px solid #dddd;
    font-size: 12px;
    padding: 2px 15px;
}

.image1 {
    width: 35%;
    max-width: 600px;
    position: absolute;
    left: 100px;
    top: 150px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0 0 20px 0px #0000001c;
}

.image2 {
    width: 35%;
    max-width: 600px;
    position: absolute;
    left: 200px;
    top: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0 0 20px 0px #0000001c;
}

.image3 {
    width: 35%;
    max-width: 600px;
    position: absolute;
    left: 50px;
    top: 450px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0 0 20px 0px #0000001c;
}

.gradient-text {
    font-size: 48px;
    font-weight: bold;
    background: linear-gradient(90deg, #285185, #d67940);
    background-size: 100% 400%;
    color: transparent;
    background-clip: text;
    animation: smoothGradient 5s ease infinite;
}