.headernav {
    align-items: center;
    background: #fff;
    display: grid;
    height: 100px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.headernav ul {
    list-style: none;
    padding: 0 10px;
}

.headernavLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 0 10px;
}

.headernavSpan {
    font-size: 12px;
}

.smart-sidebar {
    height: 40px;
    width: 100%;
    overflow: auto;
}

.smart-header {
    height: 59px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.smart-sidebar::-webkit-scrollbar {
    display: none;
}

.headerUL {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: start;
}

.headerUL li {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    white-space: nowrap;
}

.headerUL li:hover {
    cursor: pointer;
    background: #d6794000;
}

.headerUL li.active {
    background: #285185;
}

.headerUL li.active a {
    color: #fff;
}

.headerUL li a:hover {
    text-decoration: unset;
}

.headerUL li a {
    text-decoration: unset;
    color: black;
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
}